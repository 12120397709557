import dotProp from 'dot-prop-immutable';

import {
    REDUCE_INDUSTRIES
} from '../../constants/actions/industries';

const initialState = {
    industries: []
};

function industriesReducer(state = initialState, action) {
    switch (action.type) {
        case REDUCE_INDUSTRIES:
            let industries = action.payload.sort((a, b) => a.sequenceNo - b.sequenceNo);
            return dotProp.set(state, 'industries', industries);
        default:
            return state;
    }
}

export default industriesReducer;
