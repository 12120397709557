import dotProp from 'dot-prop-immutable';

import {
    REDUCE_LEAD_ELEMENT,
    REDUCE_LEAD,
    REDUCE_QUERY,
    REDUCE_QUERY_ELEMENT,
    REDUCE_BACKLOG_LEADS,
    REDUCE_BOARD_LEADS,
    REDUCE_LEADS,
    REDUCE_ENTITY,
    REDUCE_VIEW
} from '../../constants/actions/lead';

const initialState = {
    entity: null,
    lead: null,
    view: `board`,
    query: {
        board: {
            view: `board`,
            assignedTo: null,
            brokerCompanyGuid: null,
            product: null,
            take: 25,
            skip: 0,
            orderByDesc: true
        },
        backlog: {
            view: `backlog`,
            assignedTo: null,
            brokerCompanyGuid: null,
            take: 25,
            skip: 0,
            orderByDesc: true
        }
    },
    profileLeads: [],
    backlogLeads: [],
    boardLeads: []
};

function leadReducer(state = initialState, action) {
    switch (action.type) {
        case REDUCE_ENTITY:
            return dotProp.set(state, `entity`, action.payload);
        case REDUCE_LEAD_ELEMENT:
            return dotProp.set(state, `lead.${action.payload.code}`, action.payload.value);
        case REDUCE_LEAD:
            let updatedLead = dotProp.set(state, 'lead', action.payload);
            if (state.view === 'board') {
                let leadNo = updatedLead.boardLeads.findIndex((lead) => lead.guid === action.payload.guid);
                updatedLead.boardLeads[leadNo] = {
                    ...action.payload
                }
                return updatedLead;
            }
            else {
                let leadNo = updatedLead.backlogLeads.findIndex((lead) => lead.guid === action.payload.guid);
                updatedLead.backlogLeads[leadNo] = {
                    ...action.payload
                }
                return updatedLead;
            }
        case REDUCE_QUERY:
            return dotProp.set(state, `query.${state.view}`, { ...action.payload, take: 25, skip: 0, orderByDesc: true });
        case REDUCE_QUERY_ELEMENT:
            return dotProp.set(state, `query.${state.view}.${action.payload.code}`, action.payload.value);
        case REDUCE_BACKLOG_LEADS:
            return dotProp.set(state, 'backlogLeads', action.payload);
        case REDUCE_BOARD_LEADS:
            return dotProp.set(state, 'boardLeads', action.payload);
        case REDUCE_LEADS:
            return dotProp.set(state, 'profileLeads', action.payload);
        case REDUCE_VIEW:
            return dotProp.set(state, 'view', action.payload);
        default:
            return state;
    }
}

export default leadReducer;
