import React from 'react';
import {
    BrowserRouter,
    Route,
    Switch
} from 'react-router-dom';

import {
    Container,
    Layout,
    Error
} from 'metrix-common';

import AppBar from '../AppBar';

const Login = React.lazy(() => import('../../containers/Login'));
const Home = React.lazy(() => import('../../containers/Home'));
const Dashboard = React.lazy(() => import('../../containers/Dashboard'));
const ResetPassword = React.lazy(() => import('../../containers/ResetPassword'));
const Register = React.lazy(() => import('../../containers/Register'));
const Settings = React.lazy(() => import('../../containers/Settings'));
const Profile = React.lazy(() => import('../../containers/Profile'));
const ClientCompanyProfile = React.lazy(() => import('../../containers/ClientCompanyProfile'));
const Documents = React.lazy(() => import('../../containers/Documents'));
const Inbox = React.lazy(() => import('../../containers/Inbox'));
const Cases = React.lazy(() => import('../../containers/Cases'));
const Notifications = React.lazy(() => import('../../containers/Notifications'));
const Unsubscribe = React.lazy(() => import('../../containers/Unsubscribe'));
const Administration = React.lazy(() => import('../../containers/Administration'));
const Activate = React.lazy(() => import('../../containers/Activate'));
const Broker = React.lazy(() => import('../../containers/Broker'));
const BrokerCompany = React.lazy(() => import('../../containers/BrokerCompany'));
const Lead = React.lazy(() => import('../../containers/Lead'));

class Routes extends React.Component {
    render() {  
        const fallback = <Layout fullScreen loading appBar={<AppBar {...this.props} />}  />;
        const fourOhFour = {
            errorStatus: "404",
            errorMsg: "Page Not Found.",
            redirectionLocation: "/dashboard2",
            redirectionText: "Home."
        }

        const ErrorHandling = {
            errorStatus: "API Call Failed",
            errorMsg: "...",
            redirectionLocation: "/dashboard2",
            redirectionText: "Home."
        }

        return (
            <BrowserRouter>
                <Container {...this.props}>
                    <React.Suspense fallback={fallback}>
                        <Switch>
                            <Route exact path='/' render={(props) => <Login {...props} {...this.props} />} />
                            <Route exact path='/activate' render={(props) => <Activate {...props} {...this.props} />} />
                            <Route exact path='/dashboard' render={(props) => <Dashboard {...props} {...this.props} />} />
                            <Route exact path='/administration' render={(props) => <Administration {...props} {...this.props} />} />
                            <Route exact path='/resetpassword' render={(props) => <ResetPassword {...props} {...this.props} />} />
                            <Route exact path='/register' render={(props) => <Register {...props} {...this.props} />} />
                            <Route exact path='/settings' render={(props) => <Settings {...props} {...this.props} />} />
                            <Route exact path='/profile' render={(props) => <Profile key={`profile`} {...props} {...this.props} />} />
                            <Route exact path='/clientCompanyProfile' render={(props) => <ClientCompanyProfile key={`clientCompanyProfile`} {...props} {...this.props} />} />
                            <Route exact path='/documents' render={(props) => <Documents {...props} {...this.props} />} />
                            <Route exact path='/inbox' render={(props) => <Inbox key={`inbox`} {...props} {...this.props} view={`inbox`} />} />
                            <Route exact path='/cases' render={(props) => <Cases {...props} {...this.props} />} />
                            <Route exact path='/notifications' render={(props) => <Notifications {...props} {...this.props} />} />
                            <Route exact path='/unsubscribe' render={(props) => <Unsubscribe key={`unsubscribe`} {...props} {...this.props} />} />
                            <Route exact path='/broker' render={(props) => <Broker key={`broker`} {...props} {...this.props} />} />
                            <Route path='/brokerCompany' render={(props) => <BrokerCompany key={`brokerCompany`} {...props} {...this.props} />} />
                            <Route exact path='/lead' render={(props) => <Lead {...props} {...this.props} />} />
                            <Route exact path='/404' render={(props) => <Error value={fourOhFour} />} />
                            <Route exact path='/error' render={(props) => <Error value={ErrorHandling} />} />
                            <Route exact path='/unknown' render={(props) => <Error value={{
                            errorMsg: "Unknown Error!", errorStatus: "---", redirectionLocation: "/search", redirectionText: "Home."
                            }} />} />
                            <Route path={`/:page`} render={(props) => <Home {...props} {...this.props} />} />

                            <Route render={(props) => <Error value={fourOhFour} />} />
                        </Switch>
                    </React.Suspense>
                </Container>
            </BrowserRouter>
        );
    }
}

export default Routes;
