import dotProp from 'dot-prop-immutable';

import {
    REDUCE_SECURITY_ELEMENT,
    REDUCE_SECURITY,
    ENABLE_MFA,
    DISABLE_MFA
} from '../../constants/actions/security';

const initialState = {
    email: null,
    password: null,
    account: null,
    user: null,
    mfa: false,
    mfaCode: null,
};

function contextReducer(state = initialState, action) {
    switch (action.type) {
        case REDUCE_SECURITY_ELEMENT:
            return dotProp.set(state, action.payload.code, action.payload.value);
        case REDUCE_SECURITY:
            return (state = initialState);
        case ENABLE_MFA:
            return dotProp.set(state, 'mfa', true);
        case DISABLE_MFA:
            return dotProp.set(state, 'mfa', false);
        default:
            return state;
    }
}

export default contextReducer;
