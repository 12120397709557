import { all, takeLatest, fork } from 'redux-saga/effects';

import {
    GET_CRM_WEB_CONFIGURATION,
} from '../../constants/actions/CRMWebConfiguration';

import getCRMWebConfigurationSaga from './getCRMWebConfigurationSaga';

function* watchConfiguration() {
    yield takeLatest(GET_CRM_WEB_CONFIGURATION, getCRMWebConfigurationSaga);
}

function* root() {
    return yield all([
        fork(watchConfiguration),
    ]);
}

export default root;