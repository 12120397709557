import dotProp from 'dot-prop-immutable';

import {
    REDUCE_CONVERSATION,
    REDUCE_CONVERSATIONS,
    REDUCE_CONVERSATION_ELEMENT,
    REDUCE_CONVERSATION_QUERY,
    REDUCE_CONVERSATION_QUERY_ELEMENT,
    REDUCE_ASSIGNEES,
    REDUCE_INBOX,
    REDUCE_TAKE_MORE
} from '../../constants/actions/inbox';

const initialState = {
    query: {
        status: `open`,
        statusSymbol: `equal`,
        take: 10
    },
    conversations: null,
    assignees: null
};

function inboxReducer(state = initialState, action) {
    switch (action.type) {
        case REDUCE_CONVERSATION:
            if (action.payload.key === 0) {
                return dotProp.set(state, `conversations`, [action.payload.value, ...state.conversations]);
            } else {
                return dotProp.set(state, `conversations.${action.payload.key}`, action.payload.value);
            }
        case REDUCE_CONVERSATIONS:
            return dotProp.set(state, 'conversations', action.payload);
        case REDUCE_CONVERSATION_ELEMENT:
            return dotProp.set(state, `conversations.${action.payload.key}.${action.payload.code}`, action.payload.value);
        case REDUCE_CONVERSATION_QUERY:
            return dotProp.set(state, `query`, action.payload);
        case REDUCE_CONVERSATION_QUERY_ELEMENT:
            return dotProp.set(state, `query.${action.payload.code}`, action.payload.value);
        case REDUCE_ASSIGNEES:
            return dotProp.set(state, `assignees`, action.payload);
        case REDUCE_TAKE_MORE:
            return dotProp.set(state, 'query.take', state.query.take + 10);
        case REDUCE_INBOX:
            return initialState;
        default:
            return state;
    }
}

export default inboxReducer;
