import dotProp from 'dot-prop-immutable';

import {
    REDUCE_TEMPLATES,
    REDUCE_TEMPLATE
} from '../../constants/actions/template';

const initialState = {
    templates: [],
    template: null,
};

function templateReducer(state = initialState, action) {
    switch (action.type) {
        case REDUCE_TEMPLATES:
            return dotProp.set(state, 'templates', action.payload);
        case REDUCE_TEMPLATE:
            return dotProp.set(state, 'template', action.payload);
        default:
            return state;
    }
}

export default templateReducer;
