import { applyMiddleware, combineReducers, compose, createStore } from 'redux';

import createSagaMiddleware from 'redux-saga';

import {
    contextReducer,
    referenceReducer,
    documentReducer    
} from 'metrix-common';

import securityReducer from '../reducers/security';
import clientReducer from '../reducers/client';
import clientCompanyReducer from '../reducers/clientCompany';
import brokerReducer from '../reducers/broker';
import brokerCompanyReducer from '../reducers/brokerCompany';
import inboxReducer from '../reducers/inbox';
import caseReducer from '../reducers/case';
import productReducer from '../reducers/product';
import industriesReducer from '../reducers/industries';
import marketplaceReducer from '../reducers/marketplace';
import leadReducer from '../reducers/lead';
import overviewReducer from '../reducers/overview';
import CRMWebConfigurationReducer from '../reducers/CRMWebConfiguration';
import templateReducer from '../reducers/template';

export default function configureStore(initialState = {}) {

    const reducers = {
        context: contextReducer,
        security: securityReducer,
        client: clientReducer,
        clientCompany: clientCompanyReducer,
        broker: brokerReducer,
        brokerCompany: brokerCompanyReducer,
        reference: referenceReducer,
        document: documentReducer,
        inbox: inboxReducer,        
        case: caseReducer,
        product: productReducer,
        industries: industriesReducer,
        marketplace: marketplaceReducer,
        lead: leadReducer,
        overview: overviewReducer,
        CRMWebConfiguration: CRMWebConfigurationReducer,
        template: templateReducer
    };

    const sagaMiddleware = createSagaMiddleware();

    const middleware = [
        sagaMiddleware
    ];

    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === 'development';
    if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
        enhancers.push(window.devToolsExtension());
    }

    const rootReducer = combineReducers({
        ...reducers
    });

    const store = createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );

    store.injectedReducers = {};
    store.runSaga = sagaMiddleware.run;
    store.injectedSagas = {};

    return store;
}