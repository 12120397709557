import dotProp from 'dot-prop-immutable';

import {
    REDUCE_QUERY_ELEMENT,
    REDUCE_ENTITY,
    REDUCE_CASES,
    REDUCE_CASE,
    REDUCE_CASE_ELEMENT
} from '../../constants/actions/case';

const initialState = {
    entity: null,
    query: {
        take: 25,
        skip: 0,
        orderByDesc: true
    },
    cases: []
};

function caseReducer(state = initialState, action) {
    switch (action.type) {
        case REDUCE_QUERY_ELEMENT:
            return dotProp.set(state, `query.${action.payload.code}`, action.payload.value);
        case REDUCE_ENTITY:
            return dotProp.set(state, `entity`, action.payload);
        case REDUCE_CASES:
            return dotProp.set(state, 'cases', action.payload);
        case REDUCE_CASE:
            let caseIndex = state.cases.length;
            if (state.cases.some(x => x.guid === action.payload.guid)) {
                caseIndex = state.cases.findIndex(x => x.guid === action.payload.guid);
            }
            return dotProp.set(state, `cases.${caseIndex}`, action.payload);
        case REDUCE_CASE_ELEMENT:
            return dotProp.set(state, `cases.${action.payload.key}.${action.payload.code}`, action.payload.value);
        default:
            return state;
    }
}

export default caseReducer;
