export const REDUCE_LEAD_ELEMENT = 'REDUCE_LEAD_ELEMENT';
export const REDUCE_LEAD = 'REDUCE_LEAD';
export const GET_LEAD = 'GET_LEAD';
export const UPDATE_LEAD = 'UPDATE_LEAD';
export const QUERY_LEADS = 'QUERY_LEADS';
export const EXECUTE_LEAD_ACTION = 'EXECUTE_LEAD_ACTION';
export const REDUCE_QUERY = 'REDUCE_LEADS_QUERY';
export const REDUCE_QUERY_ELEMENT = 'REDUCE_LEADS_QUERY_ELEMENT';
export const REDUCE_BOARD_LEADS = 'REDUCE_BOARD_LEADS';
export const REDUCE_BACKLOG_LEADS = 'REDUCE_BACKLOG_LEADS';
export const REDUCE_LEADS = 'REDUCE_LEADS';
export const REDUCE_ENTITY = 'REDUCE_ENTITY';
export const REDUCE_VIEW = 'REDUCE_VIEW';