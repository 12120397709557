export const QUERY_CONVERSATIONS = 'QUERY_CONVERSATIONS';
export const EXECUTE_CONVERSATION_ACTION = 'EXECUTE_CONVERSATION_ACTION';
export const GET_ASSIGNEES = 'GET_ASSIGNEES';
export const REDUCE_ASSIGNEES = 'REDUCE_ASSIGNEES';
export const REDUCE_CONVERSATION_QUERY_ELEMENT = 'REDUCE_CONVERSATION_QUERY_ELEMENT';
export const REDUCE_CONVERSATION_QUERY = 'REDUCE_CONVERSATION_QUERY';
export const REDUCE_CONVERSATIONS = 'REDUCE_CONVERSATIONS';
export const REDUCE_CONVERSATION = 'REDUCE_CONVERSATION';
export const REDUCE_CONVERSATION_ELEMENT = 'REDUCE_CONVERSATION_ELEMENT';
export const REDUCE_INBOX = 'REDUCE_INBOX';
export const REDUCE_TAKE_MORE = 'REDUCE_TAKE_MORE';