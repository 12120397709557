import dotProp from 'dot-prop-immutable';

import {
    REDUCE_CRM_WEB_CONFIGURATION,
} from '../../constants/actions/CRMWebConfiguration';

const initialState = {
    CRMWebConfiguration: null,
};

function CRMWebConfigurationReducer(state = initialState, action) {
    switch (action.type) {
        case REDUCE_CRM_WEB_CONFIGURATION:
            return dotProp.set(state, 'CRMWebConfiguration', action.payload);
        default:
            return state;
    }
}

export default CRMWebConfigurationReducer;
