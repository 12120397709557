import dotProp from 'dot-prop-immutable';

import {
    REDUCE_PRODUCT
} from '../../constants/actions/product';

const initialState = {
    products: []
};

function productReducer(state = initialState, action) {
    switch (action.type) {
        case REDUCE_PRODUCT:
            let products = action.payload.sort((a, b) => a.name > b.name ? 1 : ((b.name > a.name) ? -1 : 0))
            return dotProp.set(state, 'products', products);
        default:
            return state;
    }
}

export default productReducer;
