import dotProp from 'dot-prop-immutable';

import {
    REDUCE_BROKER_API_CREDENTIAL,
    REDUCE_BROKER_ELEMENT,
    REDUCE_BROKER,
    REDUCE_QUERY_ELEMENT,
    REDUCE_BROKERS,
    REDUCE_ENTITY,
    REDUCE_BROKER_HANDLE_CODE,
    REDUCE_BROKER_HANDLE_IMAGE,
    REDUCE_BROKER_HANDLE_ERROR,
} from '../../constants/actions/broker';

const initialState = {
    entity: null,
    broker: null,
    brokerHandle:{
        code:null,
        brokerImage:{
            pictureBlob:null,
            pictureFileName:null,
            isPictureDeleted:null,
            isPictureUpdated:null
        },
        error:null
    },
    query: {
        take: 25,
        skip: 0,
        orderByDesc: true
    },
    brokers: []
};

function brokerReducer(state = initialState, action) {
    let elementIndex = 0;
    switch (action.type) {
        case REDUCE_ENTITY:
            return dotProp.set(state, 'entity', action.payload);
        case REDUCE_BROKER_ELEMENT:
            return dotProp.set(state, `broker.${action.payload.code}`, action.payload.value);
        case REDUCE_BROKER_API_CREDENTIAL:
            elementIndex = state.broker.apiCredentials.findIndex(ele => ele.apiCredentialGuid === action.payload.value.apiCredentialGuid);
            if(elementIndex >= 0){ 
                return dotProp.set(state, `broker.apiCredentials.${elementIndex}`, action.payload.value);
            } else {
                let lengthOfCredential = state.broker.apiCredentials?.length
                return dotProp.set(state, `broker.apiCredentials.${lengthOfCredential ? lengthOfCredential : `0`}`, action.payload.value);
            }
        case REDUCE_BROKER:
            return dotProp.set(state, 'broker', action.payload);
        case REDUCE_BROKER_HANDLE_CODE:
            return dotProp.set(state, 'brokerHandle.code', action.payload);
        case REDUCE_BROKER_HANDLE_IMAGE:
            return dotProp.set(state, 'brokerHandle.brokerImage', action.payload);
        case REDUCE_QUERY_ELEMENT:
            return dotProp.set(state, `query.${action.payload.code}`, action.payload.value);
        case REDUCE_BROKERS:
            return dotProp.set(state, 'brokers', action.payload);
        case REDUCE_BROKER_HANDLE_ERROR:
            return dotProp.set(state, 'brokerHandle.error', action.payload);
        default:
            return state;
    }
}

export default brokerReducer;
