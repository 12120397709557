import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import {
    withSagas
} from 'metrix-common'

import CRMWebConfigurationSaga from '../../sagas/CRMWebConfiguration/saga';

import getCRMWebConfiguration from '../../actions/CRMWebConfiguration/getCRMWebConfiguration';
import reduceCRMWebConfiguration from '../../actions/CRMWebConfiguration/reduceCRMWebConfiguration';
import { selectCRMWebConfiguration } from '../../selectors/CRMWebConfiguration/selectCRMWebConfiguration';

class CRMWebProvider extends React.Component {

    componentDidMount() {
        if (this.props.context.offline) {
            this.props.doReduceCRMWebConfiguration({});
        } else {
            this.props.doGetCRMWebConfiguration();
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (!nextProps.context.CRMWebConfiguration) {
            return true
        }
    }

    propagatePropsToChildren(children, newProps) {
        return React.Children.map(children, child => {
            if (child) {
                let childProps = {};

                if (React.isValidElement(child)) {
                    childProps = newProps;
                }

                //Don't propagate to HTML elements (e.g. div)
                if (child.props && typeof child.type !== 'string') {
                    return React.cloneElement(child, childProps);
                }

                return child;
            }
        });
    }

    render() {
        const context = {
            ...this.props.context,
            CRMWebConfiguration: this.props.CRMWebConfiguration || {}
        };
        const child = this.propagatePropsToChildren(this.props.children, { context })[0];
        return (
            <React.Fragment>
                {
                    child
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    CRMWebConfiguration: selectCRMWebConfiguration()
});

function mapDispatchToProps(dispatch) {
  return {
      doGetCRMWebConfiguration: () => dispatch(getCRMWebConfiguration()),
      doReduceCRMWebConfiguration: (payload) => dispatch(reduceCRMWebConfiguration(payload))
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withSaga = withSagas({ key: 'CRMWebConfiguration', saga: CRMWebConfigurationSaga });

export default compose(withConnect, withSaga)(CRMWebProvider);
