import dotProp from 'dot-prop-immutable';

import {
    REDUCE_GENERAL,
    REDUCE_BRANDING
    
} from '../../constants/actions/overview';

const initialState = {
    // entity: null,
    branding: null,      
};

function overviewReducer(state = initialState, action) {
    switch (action.type) {
        case REDUCE_GENERAL:
            return dotProp.set(state, `general`, action.payload);
        
        case REDUCE_BRANDING:      
            return dotProp.set(state, `branding`, action.payload);
        default:
            return state;
    }
}

export default overviewReducer;
