import dotProp from 'dot-prop-immutable';

import {
    REDUCE_INDUSTRY,
    REDUCE_CUSTOMER,
    REDUCE_ATTACHMENTS,
    REDUCE_CUSTOMER_ELEMENT,
    REDUCE_MARKETPLACE_PRODUCTS,
} from '../../constants/actions/marketplace';

const initialState = {
    attachments: {},
    industry: null,
    marketplaceProducts: [],
    customer: {}
};

function marketplaceReducer(state = initialState, action) {
    switch (action.type) {
        case REDUCE_INDUSTRY:
            return dotProp.set(state, `industry`, action.payload);
        case REDUCE_MARKETPLACE_PRODUCTS:
            return dotProp.set(state, `marketplaceProducts`, action.payload);
        case REDUCE_CUSTOMER:
            return dotProp.set(state, `customer`, action.payload);
        case REDUCE_CUSTOMER_ELEMENT:
            return dotProp.set(state, `customer.${action.payload.code}`, action.payload.value);
        case REDUCE_ATTACHMENTS:
            return dotProp.set(state, `attachments`, action.payload);
        default:
            return state;
    }
}

export default marketplaceReducer;
